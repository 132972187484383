export const helpers = {
  methods: {
    renameKeys(obj, newKeys) {
      const keyValues = Object.keys(obj).map((key) => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
      });
      return Object.assign({}, ...keyValues);
    },

    getWilayas() {
      this.$store.dispatch("wilayas/list");
    },

    getHubs() {
      this.$store.dispatch("hubs/list");
    },

    getHubTypes() {
      this.$store.dispatch("hubs/types");
    },

    getProviders() {
      this.$store.dispatch("providers/list");
    },

    getCategories() {
      this.$store.dispatch("categories/listRoots");
    },
    getSpecialCategories() {
      this.$store.dispatch("specialCategories/listRoots");
    },
    getShelves() {
      this.$store.dispatch("categories/listShelves");
    },

    getBrands() {
      this.$store.dispatch("brands/list");
    },
    getStatuses() {
      this.$store.dispatch("statuses/list");
    },

    getSubCategories() {
      this.$store.dispatch("categories/listLeafs");
    },

    getLastProductCode() {
      this.$store.dispatch("products/getLastProductCode");
    },
    getOrderOrigins() {
      this.$store.dispatch("orderOrigins/list");
    },
    getOrderStatuses() {
      this.$store.dispatch("orderStatuses/list");
    },
  },
};
